
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

/* bootstrap initial js libraries */
require('./bootstrap');

require('./agency');

/*require('./contact_me'); */

require('jquery-easing');
require('./jqBootstrapValidation');

require( 'datatables.net' );
require( 'datatables.net-bs4' );
require( 'datatables.net-fixedheader-bs4' );
require( 'datatables.net-responsive-bs4' );

require( '../assets/js/dataRender/datetime' );
require( '../assets/js/dataRender/intl' );
